import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AccessControlDirective } from "./access-control.directive";

@NgModule({
  declarations: [AccessControlDirective],
  imports: [CommonModule],
  providers: [AccessControlDirective],
  exports: [AccessControlDirective],
})
export class AccessControlModule {}
