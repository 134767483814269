import { inject } from "@angular/core";

import { Router, CanActivateFn } from "@angular/router";
import { AuthService } from "./services/shared";

export const IsApplicationUser: CanActivateFn = () => {
  const _authSvc = inject(AuthService);
  const _router = inject(Router);

  if (
    _authSvc.isAuthorized() &&
    !_authSvc.IsUserDisabled() &&
    _authSvc.getCurrentUser() !== null
  ) return true;

  _router.navigate([
    _authSvc.isAuthenticated() ? "/error/not-authorized" : "/login",
  ]);
  return false;
};


export const IsSystemAdmin: CanActivateFn = () =>{
  const _authSvc = inject(AuthService);
  const _router = inject(Router);

  if (_authSvc.isSystemAdmin()) return true;

  _router.navigate([
    _authSvc.isAuthenticated() ? "/error/not-authorized" : "/login",
  ]);
  return false;
}
