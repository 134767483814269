import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { ISoFa, ISoFaDetail, ISofaVersion, IEquipment } from "app/pages/sofa/sofa.interface";
import { map, take } from "rxjs/operators";

@Injectable()
export class SoFaService {  
  constructor(private http: HttpClient) {}

  excelDateMonth: string;

  getList({ cache = true } = {}): Observable<ISoFa[]> {
    const url = `${environment.apiEndpoint}/sofa`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }

    return this.http.get<ISoFa[]>(url, options);
  }

  getById(soFaId: number): Observable<ISoFa> {
    return this.http.get<ISoFa>(`${environment.apiEndpoint}/sofa/${soFaId}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  post(payload: ISoFa): Observable<ISoFa> {
    return this.http.post<ISoFa>(`${environment.apiEndpoint}/sofa`, payload);
  }

  put(payload: ISoFa): Observable<ISoFa> {
    return this.http.put<ISoFa>(
      `${environment.apiEndpoint}/sofa`,payload);
  }

  updateSoFaDetail(payload: ISoFaDetail[]): Observable<ISoFaDetail> {
    return this.http.put<ISoFaDetail>(
      `${environment.apiEndpoint}/sofaDetail/update-range/${payload}`,
      payload
    );
  }

  deleteRange(payload: ISoFaDetail[]): Observable<ISoFaDetail> {
    return this.http.put<ISoFaDetail>(
      `${environment.apiEndpoint}/sofaDetail/disabled-range/${payload}`,
      payload
    );
  }
  

  assignOutageToSoFa(payload: ISoFaDetail[]): Observable<any> {
    return this.http.post<ISoFaDetail>(
      `${environment.apiEndpoint}/sofaDetail/assign-outage`,
      payload
    );
  }

  updateOutageToSoFa(payload: ISoFaDetail[]): Observable<any> {
    return this.http.put<ISoFaDetail>(
      `${environment.apiEndpoint}/sofaDetail/assign-outage`,
      payload
    );
  }


  getByOutage(outageID: number): Observable<ISoFa[]> {
    return this.http.get<ISoFa[]>(
      `${environment.apiEndpoint}/sofa/get-by-outage/${outageID}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  getByOutageandVendor(
    outageID: number,
    VendorID: number
  ): Observable<ISoFa[]> {
    return this.http.get<ISoFa[]>(
      `${environment.apiEndpoint}/sofa/get-by-outage-vendor/${outageID}/${VendorID}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  getSofaVersions(
    VendorID: number,
    OutageID: number,
  ): Observable<ISofaVersion[]> {
    return this.http.get<ISofaVersion[]>(
      `${environment.apiEndpoint}/sofa/get-sofa-versions/vendor/${VendorID}/outage/${OutageID}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }
  
  importSumSofaSpreadSheet(file: File, OutageId: number): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<IAttachment[]>(
      `${environment.apiEndpoint}/sofa-excel/import-sumSofa/${OutageId}`,
      formData,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }
  
  importSofaSpreadSheet(file: File, soFaID: number, versionId: number): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<IAttachment[]>(
      `${environment.apiEndpoint}/sofa-excel/import-sofa/${soFaID}/${versionId}`,
      formData,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  downloadSumSofaSpreadSheet(outageID, outageCode?: string): Observable<any> {
    return this.http
      .get(`${environment.apiEndpoint}/sofa-excel/exportSumSofa/${outageID}`, {
        responseType: "blob",
      })
      .pipe(
        take(1),
        map((exportOutageResponse) => {
          const blob = new Blob([exportOutageResponse], {
            type: "blob",
          });

          const today = new Date();
          const date = today.toLocaleString('default', {month: 'long',});
          const mon = date.substring(0, 3);
          const dd = today.getDate();
          const yyyy = today.getFullYear();
          this.excelDateMonth = mon + "-" + dd + "-" + yyyy;

          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "sumSoFa_" + outageCode + "_" + this.excelDateMonth + ".xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      );
  }

  downloadSofaSpreadSheet(sofa: ISoFa, outageCode?: string): Observable<any> {
    return this.http
      .get(`${environment.apiEndpoint}/sofa-excel/export/${sofa.ID}`, {
        responseType: "blob",
      })
      .pipe(
        take(1),
        map((exportSofaResponse) => {
          const blob = new Blob([exportSofaResponse], {
            type: "blob",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "SoFa_" + outageCode + "_" + sofa.Vendor.Name + ".xlsx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      );
  }

  createVersion(sofaID: number, outageID: number, versionName: string): Observable<ISofaVersion> {
    return this.http
      .post<ISofaVersion>(
        `${environment.apiEndpoint}/sofa/version`,
        {},
        { params: { sofaID, outageID, versionName } }
      )
      .pipe(take(1));
  }

  delete(payload: ISoFa): Observable<ISoFa> {
    return this.http.put<ISoFa>(
      `${environment.apiEndpoint}/sofa/delete`,payload);
  }

  importSoFaStaffingPlan(equipmentStaffingPlans: IEquipment[], soFaId: number, versionId: number): Observable<any> {
    return this.http.post<any[]>(
      `${environment.apiEndpoint}/sofa/import-sofa-staffingPlan/${soFaId}/${versionId}`,
      equipmentStaffingPlans
    );
  }
}

export interface IAttachment {
  CreatedDate: string;
  FileName: string;
  FilePath: string;
  FileType: string;
  Extension: string;
}
