/* eslint-disable @typescript-eslint/naming-convention */
import { environment } from "#environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  IProject,
  IProjectElement,
  ISoFaDetail,
  ISofaEstimation,
  IStaffingPlan,
} from "app/pages/sofa/sofa.interface";
import { ReplaySubject, Observable } from "rxjs";

@Injectable()
export class SofaDetailService {
  public sofaDetailsListSubject = new ReplaySubject<ISofaEstimation>();

  resetSofaDetailsListSubject() {
    this.sofaDetailsListSubject = new ReplaySubject<any>(1);
  }

  constructor(private http: HttpClient) {}

  getList({ cache = true } = {}): Observable<ISoFaDetail[]> {
    let url = `${environment.apiEndpoint}/sofa`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }

    return this.http.get<ISoFaDetail[]>(url, options);
  }

  getSofaDetails(): Observable<ISoFaDetail[]> {
    return this.http.get<ISoFaDetail[]>(
      `${environment.apiEndpoint}/sofaDetail`
    );
  }

  getById(soFaDetailId: number, cache = true): Observable<ISoFaDetail[]> {
    let options = {};
    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }

    return this.http.get<ISoFaDetail[]>(
      `${environment.apiEndpoint}/sofaDetail/${soFaDetailId}`,
      options
    );
  }

  getBySoFaId(soFaId: number): Observable<ISoFaDetail[]> {
    return this.http.get<ISoFaDetail[]>(
      `${environment.apiEndpoint}/sofaDetail/get-by-sofa/${soFaId}`,
      {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      }
    );
  }

  getSofaDetailsCounts(sofaIds: number[]): Observable<any> {
    return this.http.post<any>(
      `${environment.apiEndpoint}/sofaDetail/get-sofa-details-counts`,
      sofaIds
    );
  }

  GetByOutageId(OutageID: number, cache = true): Observable<ISoFaDetail[]> {
    let options = {};
    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }

    return this.http.get<ISoFaDetail[]>(
      `${environment.apiEndpoint}/sofaDetail/get-by-outage/${OutageID}`,
      options
    );
  }

  getDetailsById(soFaId: number): Observable<ISofaEstimation> {
    return this.http.get<ISofaEstimation>(
      `${environment.apiEndpoint}/sofaDetail/get-details-by-sofa/${soFaId}`,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    );
  }

  post(payload: ISoFaDetail[]): Observable<ISoFaDetail[]> {
    return this.http.post<ISoFaDetail[]>(
      `${environment.apiEndpoint}/sofaDetail`,
      payload
    );
  }

  put(payload: ISoFaDetail): Observable<ISoFaDetail[]> {
    return this.http.put<ISoFaDetail[]>(
      `${environment.apiEndpoint}/sofaDetail/${payload.ID}`,
      payload
    );
  }

  updateStaffingPlan(payload: IStaffingPlan[]): Observable<IStaffingPlan[]> {
    return this.http.put<IStaffingPlan[]>(
      `${environment.apiEndpoint}/sofaDetail/update-staffing-plan/`,
      payload
    );
  }

  updateRange(payload: ISoFaDetail[]): Observable<ISoFaDetail[]> {
    return this.http.put<ISoFaDetail[]>(
      `${environment.apiEndpoint}/sofaDetail/update-range`,
      payload
    );
  }

  getProjectsByOutage(OutageID: number, cache = true): Observable<IProject[]> {
    return this.http.get<IProject[]>(
      `${environment.apiEndpoint}/SumSoFA/get-project/${OutageID}`
    );
  }

  getProjectElementsByOutage(
    OutageID: number,
    cache = true
  ): Observable<IProjectElement[]> {
    return this.http.get<IProjectElement[]>(
      `${environment.apiEndpoint}/SumSoFA/get-project-element/${OutageID}`
    );
  }

  getSumSoFAByOutage(
    OutageID: number,
    cache = true
  ): Observable<ISoFaDetail[]> {
    return this.http.get<ISoFaDetail[]>(
      `${environment.apiEndpoint}/SumSoFA/get-sumsofa/${OutageID}`
    );
  }
}
