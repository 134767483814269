import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "#environments/environment";
import { LoggedUser, User } from "app/models";
import { IUserRole } from "app/helpers/interfaces/user/user-role.interface";
import { ISiteUser } from "app/helpers/interfaces/user/site-user.interface";

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}

  getCurrentUser(): Observable<LoggedUser> {
    return this.http.get<LoggedUser>(
      `${environment.apiEndpoint}/users/current`
    );
  }

  post(user: any): Observable<User> {
    return this.http.post<User>(`${environment.apiEndpoint}/users`, {
      firstName: user.FirstName,
      lastName: user.LastName,
      email: user.Email,
      cai: user?.CAI,
      disclaimerAccepted: false,
      lastLoginDate: new Date().toISOString(),
      isSystemAdmin: user.IsSystemAdmin,
      isDisabled: false,
      businessUnitID: user?.BusinessUnitID,
    });
  }

  put(user: any): Observable<User> {
    return this.http.put<User>(
      `${environment.apiEndpoint}/users`,
      {},
      {
        params: {
          userID: +user?.ID,
          businessUnitID: +user?.BusinessUnitID,
        },
      }
    );
  }

  setStatus(userId: number, status: "enable" | "disable"): Observable<User> {
    return this.http.post<User>(
      `${environment.apiEndpoint}/users/${userId}/${status}`,
      {}
    );
  }

  postBySite(user: User, siteId: number): Observable<User> {
    return this.http.post<User>(
      `${environment.apiEndpoint}/sites/${siteId}/users`,
      {
        ...user,
        ID: undefined,
      }
    );
  }

  putBySite(user: User, siteId: number): Observable<User> {
    return this.http.post<User>(
      `${environment.apiEndpoint}/sites/${siteId}/users`,
      {
        ...user,
      }
    );
  }

  acceptDisclaimer(userId: number): Observable<User> {
    return this.http.post<User>(
      `${environment.apiEndpoint}/users/current/accept-disclaimer`,
      null
    );
  }

  getUsers(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiEndpoint}/users`);
  }

  getParticipants(query: string): Observable<User[]> {
    const params = new HttpParams().set("query", query);
    return this.http.get<User[]>(
      `${environment.apiEndpoint}/users/participants`,
      { params }
    );
  }

  getById(userId: number): Observable<User> {
    return this.http.get<User>(`${environment.apiEndpoint}/users/${userId}`);
  }

  grant(userId: number): Observable<User> {
    return this.http.post<User>(
      `${environment.apiEndpoint}/users/${userId}/grant-sysadmin-role`,
      {}
    );
  }

  revoke(userId: number): Observable<User> {
    return this.http.post<User>(
      `${environment.apiEndpoint}/users/${userId}/revoke-sysadmin-role`,
      {}
    );
  }

  getRoles(userID: number): Observable<IUserRole[]> {
    return this.http.get<IUserRole[]>(
      `${environment.apiEndpoint}/roles/${userID}`
    );
  }

  postRole(siteUser: ISiteUser): Observable<IUserRole> {
    return this.http.post<IUserRole>(
      `${environment.apiEndpoint}/roles`,
      {
        ...siteUser,
      }
    );
  }

  putRole(siteUser: ISiteUser): Observable<IUserRole> {
    return this.http.put<IUserRole>(
      `${environment.apiEndpoint}/roles`,
      {
        ...siteUser,
      }
    );
  }

  deleteRole(siteUser: ISiteUser): Observable<void>{
    return this.http.delete<void>(
      `${environment.apiEndpoint}/roles`,
      {
        body: siteUser,
      }
    );
  }

}
