import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './not-found/not-found.component';
import { GenericErrorComponent } from './generic-error/generic-error.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { DBUnavailableComponent } from './db-unavailable/db-unavailable.component';
import {TranslateModule,} from '@ngx-translate/core';

@NgModule({
    declarations: [
        NotFoundComponent,
        GenericErrorComponent,
        NotAuthorizedComponent,
        DBUnavailableComponent
    ],
    imports: [
        CommonModule,

        //translate
        TranslateModule
    ],
    providers: [
    ]
})

export class ErrorPagesModule { }
