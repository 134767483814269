import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFavoriteOutage } from 'app/pages/outage/favorite-outage.interface';
import { Observable } from 'rxjs';

@Injectable()
export class FavoriteOutageService {
  constructor(private http: HttpClient) {}

  put(payload: IFavoriteOutage): Observable<IFavoriteOutage> {
    return this.http.put<IFavoriteOutage>(`${environment.apiEndpoint}/favorite-outage`, payload);
  }
}
