import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { BusinessUnit } from "app/models";
import { BusinessUnitsService } from "app/services/api/business-unit.service";
import { LoadingIndicatorService } from "app/services/shared";
import { MessageService } from "primeng/api";
import { catchError, of } from "rxjs";

@Component({
  selector: "app-business-units",
  templateUrl: "./business-units.component.html",
})
export class BusinessUnitsComponent implements OnInit {
  loading: boolean = false;
  businessUnits: Array<BusinessUnit> = [];
  businessUnitForm = new UntypedFormGroup({
    Name: new UntypedFormControl("", Validators.required),
    Id: new UntypedFormControl(0),
  });

  constructor(
    private readonly _businessUnitsSvc: BusinessUnitsService,
    private readonly _loadingIndicatorSvc: LoadingIndicatorService,
    private readonly _messageService: MessageService,
    private readonly _router: Router
  ) {}

  ngOnInit(): void {
    this.loadBusinessUnits();
  }

  create(): void {
    this._loadingIndicatorSvc.show();
    this._businessUnitsSvc.create(this.businessUnitForm.value).subscribe({
      next: (data) => {
        this.businessUnits.push(data);
        this.businessUnitForm.reset();
      },
      complete: () => this._loadingIndicatorSvc.hide(),
    });
  }

  setStatus(businessUnitID: number, disable: boolean) {
    this._loadingIndicatorSvc.show();
    let method = disable ? "disableBusinessUnit" : "enableBusinessUnit";
    this._businessUnitsSvc[method](businessUnitID).subscribe({
      next: (data) => {
        this.businessUnits = [
          ...this.businessUnits.filter((item) => item.ID !== data.ID),
          { Name: data.Name, IsDisabled: data.IsDisabled, ID: data.ID },
        ];
        this._messageService.add({
          severity: "success",
          summary: "success!",
          detail: `business unit was ${
            disable ? "disabled" : "enabled"
          } successfully`,
          life: 5000,
        });
      },
      error: () => {
        this._messageService.add({
          severity: "error",
          summary: "error!",
          detail: `there was an error ${
            disable ? "disabling" : "enabling"
          } the business unit, try again`,
          life: 5000,
        });
      },
      complete: () => this._loadingIndicatorSvc.hide(),
    });
  }

  private loadBusinessUnits(): void {
    this._loadingIndicatorSvc.show();
    this._businessUnitsSvc.getList().pipe(
      catchError((error) => {
        if (error.status === 403) {
          this._router.navigate(["/error/not-authorized"]);
          return of(null)
        }
      })
    ).subscribe({
      next: (data: BusinessUnit[]) => {
        this.businessUnits = data;
      },
      complete: () => this._loadingIndicatorSvc.hide(),
    });
  }
}
